export default function scrollFullSpecs() {
    const specMenu = document.querySelector('.fullspecs__menu')

    if (window.matchMedia("(max-width: 1000px)").matches && specMenu) {
        const specMenuPadding = parseFloat(window.getComputedStyle(specMenu).paddingLeft) * 4 //из-за отр. margin умножаем на 4, а не на 2
        const specItems = document.querySelectorAll('.fullspecs__menu-item')
        const specItemsCount = specItems.length
        const specItemsArray = Array.prototype.slice.call(specItems)

        const offset = (document.documentElement.clientWidth / 2 - specMenuPadding) - (specItems[0].offsetWidth / 2)

        specItems[0].style.paddingLeft = `${offset}px`;
        specItems[specItemsCount - 1].style.paddingRight = `${offset}px`;
        
        specItems.forEach(item => item.addEventListener('click', function () {
            const tabNumber = Number(item.getAttribute('data-tab-page'))
            calcLeftScroll(tabNumber)
        }))

        function calcLeftScroll(number) {
            const newSpecItems = specItemsArray.slice(0, number)
            let scrollLeftValue = 0
            newSpecItems.forEach((item, i) => {
                if (newSpecItems.length !== i + 1) {
                    scrollLeftValue += item.offsetWidth
                } else {
                    scrollLeftValue = (document.documentElement.clientWidth / 2) - ((scrollLeftValue) + (item.offsetWidth / 2))
                    specMenu.scrollLeft = scrollLeftValue * -1
                }
            })
        }
    }
}