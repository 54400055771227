export default function initStartVideo() {
    const videoBtns = document.querySelectorAll(".fp-slider__video-play")
    
    videoBtns.forEach(videoBtn => videoBtn.addEventListener('click', function()  {
        videoBtn.parentNode.querySelector('video').play()
        videoBtn.classList.add('hide')
    }))

    let bannerVideoBtn = document.querySelector(".banner__video-play")
    if(bannerVideoBtn) {
        bannerVideoBtn.addEventListener('click', function(){
            setTimeout(() => {
                body.querySelector('.popup--video.opened video').play()
            }, 100)
        })
    }
    
}
