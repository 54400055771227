export default class Select {
    /**
     *
     * @param {String} selector - Query Selector
     * @param {String} prefix - Префикс для класса (Пример: был .select станет .prefix-select)
     */
    constructor(selector, options = {}) {
        this.prefix = options.prefix ? options.prefix + "-" : ""
        this.selects = document.querySelectorAll(selector)
        this.customSelects = []

        this.openedSelect = null
        this.wrapSelects()
    }

    wrapSelects() {
        this.selects?.forEach((select) => {
            // Hide original select
            select.style.display = "none"

            // create custom html
            let newSelect = this.createNewSelect(select)
            select.insertAdjacentElement("afterend", newSelect)

            // Засовываем старый селект внутрь нового для удобства
            newSelect.append(select)
        })
        this.setEventListeners()
    }

    createNewSelect(select) {
        let div = document.createElement("div")
        const label = select.options[0]

        div.classList.add(`${this.prefix}select`)
        div.innerHTML = `
                <div class="${this.prefix}select__input">${select.options[select.selectedIndex].innerText}</div>
                <div class="${this.prefix}select__list">
                    ${this.createOptions(select.options)}
                </div>
                <div class="${this.prefix}select__label">${label.innerText}</div>
        `
        this.customSelects.push(div)

        setTimeout(() => {
            if(label.innerText != select.options[select.selectedIndex].innerText) {
                select.parentNode.querySelector('.select__label').classList.add('active')
            }
        }, 100);

        return div
    }

    /**
     *
     * @param {object} options list of select options
     * @returns {string} html of option elements
     */
    createOptions(options) {
        let html = ""
        Array.from(options).forEach((option) => {
            let attributes = ""
            Array.from(option.attributes).forEach((attr) => {
                attributes = attributes + `${attr.name}= "${attr.value}"`
            })

            html =
                html +
                `<div ${attributes} class="${this.prefix}select__list-item">
                <span>${option.innerText}</span>
            </div>`
        })

        return html
    }

    open(select) {
        this.openedSelect = select
        select.classList.add("opened")
    }

    close() {
        if (this.openedSelect) {
            this.openedSelect.classList.remove("opened")
            this.openedSelect = null
        }
    }

    setEventListeners() {
        // OPEN/CLOSE by click on input
        this.customSelects.forEach((select) => {
            select.children[0].addEventListener("click", (event) => {
                if (this.openedSelect) {
                    this.close()
                } else {
                    this.open(select)
                }
            })
        })

        // SET active option
        this.customSelects.forEach((select, index) => {
            select.children[1].addEventListener("click", (e) => {
                const value = e.target.getAttribute("value")
                const label = select.querySelector('.select__label')
                if (value) {
                    this.selects[index].value = value
                    select.children[0].innerText = e.target.innerText
                    label.classList.add('active')
                    this.close()
                }
            })
        })

        // outside click close
        document.addEventListener("click", (e) => {
            if (!this.openedSelect) return

            if (!this.openedSelect.contains(e.target)) {
                this.close()
            }
        })
    }
}
