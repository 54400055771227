import Swiper, {Navigation,Pagination,EffectFade,Autoplay,Mousewheel,Lazy,Thumbs} from "swiper"

Swiper.use([Navigation, Pagination, EffectFade, Thumbs])

document.addEventListener("DOMContentLoaded", () => {
    initSliders()
})

function initSliders() {
    const frontpageSlider = document.querySelectorAll(".fp-slider .swiper-container")
    frontpageSlider.forEach((slider) => {
        const swiper = new Swiper(slider, {
            slidesPerView: 1,
            autoHeight: true,
            loop: true,
            effect: "fade",
            navigation: {
                prevEl: slider.parentElement.querySelector(".swiper-buttons-prev"),
                nextEl: slider.parentElement.querySelector(".swiper-buttons-next"),
            },
            breakpoints: {
                1800: {
                    pagination: {
                        el: slider.parentElement.querySelector(".swiper-pagination"),
                        type: "fraction",
                        renderFraction: function (currentClass, totalClass) {
                            return '<span class="' + currentClass + '"></span>' + '/' + '<span class="' + totalClass + '"></span>'
                        }
                    },

                },
                320: {
                    pagination: {
                        el: slider.parentElement.querySelector(".swiper-pagination"),
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                        clickable: true,
                        type: 'bullets',
                        
                    },
                },
            }
        })
        let sliderImages = slider.querySelectorAll('.fp-slider__img')
        sliderImages.forEach(sliderImage => sliderImage.addEventListener('click', function()  {
            sliderImage.closest('.fp-slider').querySelector('.swiper-buttons-next').click()
        }))
        if(slider.querySelectorAll('.swiper-slide').length <= 1) {

            slider.querySelector('.swiper-pagination').classList.add('hide')
            if(slider.parentNode.querySelector('.swiper-buttons')) {
                slider.parentNode.querySelector('.swiper-buttons').classList.add('hide')
            }
        }
    })

    // Слайдер новостей на главной
    const newsSlider = document.querySelector(".news__slider .swiper-container")
    if (newsSlider) {
        const swiper = new Swiper(newsSlider, {
            spaceBetween: 10,
            loop: true,
            navigation: {
                prevEl: newsSlider.querySelector(".swiper-nav-prev"),
                nextEl: newsSlider.querySelector(".swiper-nav-next"),
            },
            breakpoints: {
                1100: {
                    pagination: {

                    },
                },
                320: {
                    pagination: {
                        el: newsSlider.parentElement.querySelector(".swiper-pagination"),
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                        clickable: true,
                        type: 'bullets'
                    },
                },
            }

        })
        const nextBtns = newsSlider.querySelectorAll(".swiper-buttons-next")
        const prevBtns = newsSlider.querySelectorAll(".swiper-buttons-prev")

        nextBtns.forEach(nextBtn => nextBtn.addEventListener('click', function()  {
            newsSlider.querySelector(".swiper-nav-next").click()
        }))
      
        prevBtns.forEach(prevBtn => prevBtn.addEventListener('click', function()  {
            newsSlider.querySelector(".swiper-nav-prev").click()
        }))

    }


    // Слайдер новостей на странице новостей
    const articleNews = document.querySelector(".news-page__article .swiper-container")
    if (articleNews && window.matchMedia("(max-width: 768px)").matches) {
        const swiper = new Swiper(articleNews, {
            spaceBetween: 10,
            navigation: {
                prevEl: articleNews.querySelector(".swiper-nav-prev"),
                nextEl: articleNews.querySelector(".swiper-nav-next"),
            },
            breakpoints: {
                1100: {
                    slidesPerView: 0,
                    pagination: {

                    },
                },
                320: {
                    pagination: {
                        el: articleNews.parentElement.querySelector(".swiper-pagination"),
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                        clickable: true,
                        type: 'bullets',
                    },
                },
            }

        })
    

    }


}