export default function initFixedHeader() {
    let header = document.querySelector('.header')
    let lastScrollTop = 0
    window.onscroll = () => {
        let scrollTopPosition = document.documentElement.scrollTop
        if (scrollTopPosition > lastScrollTop) {
            header.classList.remove('fixed')
        }
        else {
            header.classList.add('fixed')
        }

        lastScrollTop = scrollTopPosition

        if (scrollTopPosition > 50) {
            header.classList.add('hide')    
        }
        else {
            header.classList.remove('hide')
            header.classList.remove('fixed')
        }
    }
}