
// PLUGINS======================================================================================
// import Inputmask from "inputmask"
//import {Fancybox} from "@fancyapps/ui"
// import LazyLoad from "vanilla-lazyload"
import CI360 from "js-cloudimage-360-view/dist/index.js"


// MODULES======================================================================================

// import initDropdowns from './js-modules/dropdowns'
import Toast from "./js-modules/toast";
// import initAnimations from "./js-modules/animations"
// import initShowmore from "./js-modules/showmore"
import initMobilemenu from "./js-modules/mobilemenu"
import "./js-modules/sliders.js"
import initTabs from "./js-modules/tabs"
import Popup from "./js-modules/popup"
import Select from "./js-modules/custom-select.js"
import initFixedHeader from "./js-modules/fixedHeader"
import initCopyPromo from "./js-modules/copyPromo"
import initProfile from "./js-modules/profile"
import initSearchInput from "./js-modules/searchInput"
import initStartVideo from "./js-modules/startVideo"
import initOpenSocial from "./js-modules/openSocial"
//import initToggleBike from "./js-modules/toggleBike"
import initTextareaMore from "./js-modules/textareaMore"
import initScrollMenuProfile from "./js-modules/scrollMenuProfile"
import initHoverTabs from "./js-modules/hoverTabs"
import initScrollFullSpecs from "./js-modules/scrollFullSpecs"

// init ========================================================================================
document.addEventListener("DOMContentLoaded", () => {
    // let lazyload = new LazyLoad()
    // Fancybox.bind("[data-fancybox]", {
    //     Toolbar: false,
    //     dragToClose: false,
    //     Thumbs: false,
    // })
    
    let gallery360 = document.querySelector('.cloudimage-360')
    if(gallery360) {
        window.CI360.init()
    }

    new Select(".custom-select")
    window.popup = new Popup('[data-popup]')
    initFixedHeader()
    initCopyPromo()
    initProfile()
    initTabs()
    initMobilemenu()
    initSearchInput()
    initStartVideo()
    initOpenSocial()
    //initToggleBike()
    initTextareaMore()
    initScrollMenuProfile()
    initHoverTabs()
    initScrollFullSpecs()
    // let im = new Inputmask("+7 (999) 999-99-99")
    // im.mask(document.querySelectorAll('input[type="tel"]'))
    // initShowmore()
    // initDropdowns()
    window.toast = new Toast()
    // initAnimations()
    
})
