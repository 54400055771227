export default function initHoverTabs() {
    const hoverBlock = document.querySelectorAll("[data-hover]")
    const isMobile = window.matchMedia("(max-width: 768px)").matches

    if(!hoverBlock) return
    
    hoverBlock.forEach((block) => {
        const hoverItems = Array.from(block.children) 
        const hoverContainer = block.getAttribute('data-hover')
        const hoverContent  = document.querySelectorAll(`${hoverContainer}`)
        
        hoverItems.forEach((item, i) => {
            item.addEventListener(isMobile ? 'click' : "mouseenter", function() {
                event.preventDefault()

                hoverItems.forEach((e) => e.classList.remove("active"))
                hoverContent.forEach((e) => e.classList.remove("active"))
                
                item.classList.add('active')
                hoverContent[i].classList.add('active')
            })
        })
    })
}