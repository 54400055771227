export default function initTextareaMore() {
    //Увеличение textarea по мере заполнения
    let textareaList = document.querySelectorAll('.comments .comments__form-input');

    textareaList.forEach(textarea => textarea.addEventListener('keyup', function()  {
        if(this.scrollTop > 0 ) {
            if(this.scrollHeight < 304) {
                this.style.height = this.scrollHeight + "px"
            }
            else {
                textarea.classList.add('scroll')
            }
        }
    }))

}




