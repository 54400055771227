export default function scrollMenuProfile() {
    let scrollMenu = document.querySelector('.profile__menu')
    let scrollMenuItems = document.querySelectorAll('.profile__menu-item')

    if (window.matchMedia("(max-width: 1160px)").matches) {
        scrollMenuItems.forEach((menuItem, i) => {
            if (i === 0 && menuItem.classList.contains('active')) {
                let offsetLeft = (document.documentElement.clientWidth / 2) - (menuItem.offsetWidth / 2)
                menuItem.style.paddingLeft = `${offsetLeft}px`;
            }

            if (i === 1 && menuItem.classList.contains('active')) {
                let scrollingLeft = (scrollMenuItems[0].offsetWidth)  - ((document.documentElement.clientWidth / 2) - (menuItem.offsetWidth / 2))
                scrollMenu.scrollLeft = scrollingLeft
            }

            if (i === 2 && menuItem.classList.contains('active')) {
                let scrollingLeft = (scrollMenuItems[0].offsetWidth + scrollMenuItems[1].offsetWidth) - ((document.documentElement.clientWidth / 2) - (menuItem.offsetWidth / 2))
                scrollMenu.scrollLeft = scrollingLeft
            }

            if (i === 3 && menuItem.classList.contains('active')) {
                let scrollingLeft = (scrollMenuItems[0].offsetWidth + scrollMenuItems[1].offsetWidth + scrollMenuItems[2].offsetWidth) - ((document.documentElement.clientWidth / 2) - (menuItem.offsetWidth / 2))
                scrollMenu.scrollLeft = scrollingLeft
            }
        })
    }
}