export default function initFixedHeader() {
    let copyBtns = document.querySelectorAll('.invite__promo-copy')
    let copyInputs = document.querySelectorAll('.invite__promo')

    copyBtns.forEach(copyBtn => copyBtn.addEventListener('click', function()  {
        copyBtn.parentNode.classList.add('copied')
        copyBtn.parentNode.querySelector('.invite__promo-code').select()
        document.execCommand("copy")
        copyBtn.parentNode.querySelector('.invite__promo-tooltip').innerText = 'Copied'
        
        setTimeout(() => {
            copyBtn.parentNode.classList.remove('copied')
            copyBtn.parentNode.querySelector('.invite__promo-tooltip').innerText = 'Copy to clipboard'
        }, 3000);
    }))

    copyInputs.forEach(copyInput => copyInput.addEventListener('click', function()  {
        copyInput.querySelector('.invite__promo-code').select()
    }))
}